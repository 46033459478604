import store from "@/state/store";
import i18n from "../../i18n";

import {
  GamesTypes,
  getEarnings,
  formattBets,
  addEarningsToBet,
  addEarningsToBetDragon,
  formatResponse,
  formatAllTransactions,
  formatTransaction,
  transactionAllFields,
  formatAllRounds,
} from "../helpers/index";

import {
  blackJackRoundFields,
  dragonTigerRoundsFields,
  dragonTigerTransactionsFields,
  externalGamesFields,
  globalFields,
  rouletteFields,
  rouletteRoundsFields,
  wheelRoundsFields,
  blackJackTransactionsFields,
  wheelsTransactionsFields,
  historyRouletteFields,
  historyAllFields,
  historyWheelFields,
  historyExternalGamesFields,
  wheelBetDetail,
  wheelBetDetailEarnings,
  wheelBetDetailJackpot,
  jackpotRouletteFields,
  jackpotWheelFields,
} from "../fields";

import {
  axiosIns,
  axiosInsBlackJack,
  axiosInsDragonTiger,
  axiosInsWheel,
} from "../../helpers/axios/index";

import {
  calculateTotal,
  concatData,
  concatTotalized,
  formatGgrByCasinoAll,
  formatGgrByPlayerAll,
  formatGgrByWlsAll,
  getTotal,
  getTotalByWls,
  initialValue as initialTotalGgrValue
} from "../helpers/formatGgr";

import { allGamesFields } from "../fields/rounds";

export const state = {
  data: [],
  bet: null,
  result: null,
  viewsBets: [],
  fields: [],
  visits: {},
  rounds: [],
  earnings: null,
  ggr: { data: [], total: initialTotalGgrValue },
  wls: [],
  statistics: [],
  options: {
    labels: [],
  },
  series: [],
  jackpots: [],
  ggrPlayers: [],
  ggrPlayersTotalized: {},
  total: {},
  jackpotWheel: null,
  reds: [
    1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 25, 27, 30, 32, 34, 36,
  ],
  success: false,
  groupedCount: 0
};

export const getters = {
  getReds(state) {
    return state.reds
  },
  getData() {
    return state.data;
  },
  getBet() {
    return state.bet;
  },
  getBetEarnings() {
    return state.earnings;
  },
  getResult() {
    return state.result;
  },
  getViewsBets() {
    return state.viewsBets;
  },
  getFields() {
    return state.fields;
  },
  getHistoryFields: () => (type) => {
    let fields = [];

    switch (type) {
      case GamesTypes.Roulette:
        fields = historyRouletteFields;
        break;
      case GamesTypes.ExternalGames:
        fields = historyExternalGamesFields;
        break;
      case GamesTypes.WheelFortune:
        fields = historyWheelFields;
        break;
      case GamesTypes.ALL:
        fields = historyAllFields;
        break;

      default:
        fields = historyAllFields;
        break;
    }

    return fields;
  },
  getRounds() {
    return state.rounds;
  },
  getGgr() {
    return state.ggr;
  },
  showResult: () => (filter, data) => {
    let text = "";

    switch (filter.gameType) {
      case GamesTypes.Roulette:
        text = data.result === -1 ? "" : JSON.stringify(data.result);
        break;
      case GamesTypes.DragonTiger:
        text = "MOSTRAR CARTAS";
        break;
      case GamesTypes.BlackJack:
        text = "MOSTRAR CARTAS";
        break;
      case GamesTypes.WheelFortune:
        text = data.result ? data.result : "";
        break;
      case GamesTypes.ALL:
        text = data.result === -1 ? "" : JSON.stringify(data.result);
        break;
      default:
        text = data.result === -1 ? "" : JSON.stringify(data.result);
        break;
    }
    return text;
  },
  colorResult: (state) => (result) => {

    return result === -1
      ? ""
      : state.reds.includes(result)
        ? "red"
        : [37, 0].includes(result)
          ? "green"
          : "black";
  },
  mapIsButton: () => (filter) => {
    let text = "";

    switch (filter.gameType) {
      case GamesTypes.DragonTiger:
        text = "btn btn-dark";
        break;
      case GamesTypes.BlackJack:
        text = "btn btn-dark";
        break;
      default:
        break;
    }
    return text;
  },
  getWls(state) {
    return state.wls;
  },
  getGamesTypes() {
    return Object.values(GamesTypes).map((type) => {
      return {
        value: type,
        name: i18n.t(`gamesTypes.${type}`),
      };
    });
  },
  gamesTypes() {
    return GamesTypes;
  },
  formatNumbers: () => (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = "$1,";
    return number.toFixed(2).toString().replace(exp, rep);
  },
  cleanFilter: () => (filter) => {
    if (filter.params.rouletteId === "*") {
      filter.params.rouletteId = "";
    }
    if (filter.params.currency === "*") {
      filter.params.currency = "";
    }

    if (filter.params.fromDate) {
      const fromDate =
        filter.params.fromDateHour || filter.params.fromDate;

      filter.params.fromDate = fromDate
    }

    if (filter.params.toDate) {
      const toDate =
        filter.params.toDateHour || filter.params.toDate;

      filter.params.toDate = toDate
    }

    if (filter.params.startDate) {
      const startDate =
        filter.params.startDateHour || filter.params.startDate;

      filter.params.startDate = startDate
    }

    if (filter.params.endDate) {
      const endDate =
        filter.params.endDateHour || filter.params.endDate;

      filter.params.endDate = endDate
    }

    return filter;
  },
  mapRoundStatistics() {
    return state.statistics;
  },
  mapOptions() {
    return state.options;
  },
  mapSeries() {
    return state.series;
  },
  mapJackpots() {
    return state.jackpots;
  },
  mapGgrPlayers() {
    return state.ggrPlayers;
  },
  mapGgrPlayersTotalized() {
    return state.ggrPlayersTotalized;
  },
  getType: () => (gameType, item) => {
    const { gameType: gameTypeFromGame } = item;
    let type = "";

    switch (gameType) {
      case GamesTypes.Roulette:
        type = gameType;
        break;
      case GamesTypes.BlackJack:
        type = gameType;
        break;
      case GamesTypes.DragonTiger:
        type = gameType;
        break;
      case GamesTypes.ExternalGames:
        type = gameType;
        break;
      case GamesTypes.WheelFortune:
        type = gameType;
        break;
      case GamesTypes.ALL:
        type = gameTypeFromGame;
        break;
      default:
        type = gameTypeFromGame;
        break;
    }

    return type;
  },
  getTotalBet(state) {
    return state.total;
  },
  getJackpotWheel(state) {
    return state.jackpotWheel;
  },
  formatRouletteResults: () => (result) => {
    return result == 37
      ? '00'
      : result == -1
        ? ''
        : result
  },
  getBtnFilterText: () => (fetching) => {
    const text = i18n.t("filter.filter")
    if (!fetching) return text
    return `${text} ${store.getters['notification/getLoadingState']}`
  },
  formatTimeToUtc: () => (newDate) => {
    const date = new Date().toString().split(' ')
    date.splice(4, 1, `${newDate}:00`)
    const formatted = new Date(date.join(' ')).toUTCString().split(' ')[4]
    return formatted
  },
  formatTimefromUtcToLocal: () => (newDate) => {
    let utcTime = new Date(`2024-05-10T${newDate}Z`); // Z denotes UTC time
    let localTime = utcTime.toLocaleString().split(',')[1];
    return localTime
  },
  isSuccess(state) {
    return state.success
  },
  getGroupedCount(state) {
    return state.groupedCount
  }
};

export const mutations = {
  setData(state, payload) {
    state.data = payload;
  },
  setBet(state, payload) {
    state.bet = payload;
  },
  setEarnings(state, payload) {
    state.earnings = payload;
  },
  setRoundResult(state, payload) {
    state.result = payload;
  },
  setViewsBets(state, payload) {
    state.viewsBets = payload;
  },
  setFields(state, payload) {
    state.fields = payload;
  },
  setVisits(state, payload) {
    state.visits = [];
    state.visits = payload;
  },
  setRounds(state, payload) {
    state.rounds = payload;
  },
  setJackpots(state, payload) {
    state.jackpots = payload;
  },
  setGgr(state, payload) {
    state.ggr = payload;
  },
  setWls(state, payload) {
    state.wls = payload;
  },
  setStatistics(state, payload) {
    state.statistics = payload;
  },
  setOptions(state, payload) {
    state.options = payload;
  },
  setSeries(state, payload) {
    state.series = payload;
  },
  setGgrPlayers(state, payload) {
    state.ggrPlayers = payload;
  },
  setGgrPlayersTotalized(state, payload) {
    state.ggrPlayersTotalized = payload;
  },
  setTotalBet(state, payload) {
    state.total = payload;
  },
  setJackpotWheel(state, payload) {
    state.jackpotWheel = payload;
  },
  resetGgr(state) {
    state.ggr = { data: [], total: initialTotalGgrValue }
  },
  setSuccess(state, payload) {
    state.success = payload
  },
  setGroupedCount(state, payload) {
    state.groupedCount = payload
  },
};

export const actions = {
  async fetchReports(
    { commit },
    { gameType, oldTransactions, type = "data", options: filter }
  ) {
    const options = store.getters["reports/cleanFilter"](filter);
    let data = [];

    try {
      switch (gameType) {
        case GamesTypes.ExternalGames:
          {
            if (oldTransactions) {
              data = await store.dispatch("reports/fetchTransactionsRoulette", {
                path: "external-games/all-transactions-old",
                options,
                type,
              });
            } else {
              data = await store.dispatch("reports/fetchTransactionsRoulette", {
                path: "external-games/all-transactions",
                options,
                type,
              });
            }

            const fields = [...externalGamesFields, ...globalFields];
            commit("setFields", fields);
          }
          break;
        case GamesTypes.Roulette:
          {
            data = await store.dispatch("reports/fetchTransactionsRoulette", {
              path: "transactions",
              type: "transactions",
              options,
            });

            const fields = [...rouletteFields, ...globalFields];
            commit("setFields", fields);
          }
          break;
        case GamesTypes.DragonTiger:
          {
            data = await store.dispatch("reports/fetchTransactionsDragon", {
              options,
            });
            const fields = [...dragonTigerTransactionsFields];
            commit("setFields", fields);
          }
          break;
        case GamesTypes.WheelFortune:
          {
            data = await store.dispatch(
              "reports/fetchTransactionsWheelFortune",
              {
                options,
              }
            );

            const fields = [...wheelsTransactionsFields];
            commit("setFields", fields);
          }
          break;
        case GamesTypes.BlackJack:
          {
            data = await store.dispatch("reports/fetchTransactionsBlackJack", {
              options,
            });

            const fields = [...blackJackTransactionsFields];
            commit("setFields", fields);
          }
          break;
        case GamesTypes.ALL:
          {
            data = await store.dispatch("reports/fetchReportsAllGames", {
              options,
              oldTransactions,
              type,
            });
            commit("setFields", transactionAllFields);
          }
          break;
        default:
          {
            data = await store.dispatch("reports/fetchReportsAllGames", {
              options,
              oldTransactions,
              type,
            });
            commit("setFields", transactionAllFields);
          }
          break;
      }

      if (!gameType || gameType === "ALL") return commit("setData", data);

      const formattedData = formatTransaction(data, gameType);
      commit("setData", formattedData);
    } catch (error) {
      console.log("ERROR FETCH REPORTS", error);
    }
  },
  async fetchTransactionsRoulette(_, { path, options, type }) {
    try {
      const { data, status } = await axiosIns.get(`/reports/${path}`, options);

      if (status > 200) return [];

      return data[type];
    } catch (err) {
      console.log("ERROR FETCH TRANSACTIONS", err);
    }
  },
  async fetchTransactionsDragon(_, { options }) {
    const { params } = options;

    const {
      rouletteId: game,
      operatorId,
      clientId,
      currency,
      fromDate: start_date,
      toDate: end_date,
      playerId: player,
    } = params;

    const filter = { game, operatorId, clientId, currency, start_date, end_date, player }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter, type: GamesTypes.DragonTiger })

    try {
      const { data, status } = await axiosInsDragonTiger.get(
        "reports/transactions",
        {
          params: {
            ...params,
            ...filterUuid,
          },
        }
      );

      if (status > 201) return [];

      if (!data.transactions.length) return [];

      const formatted = data.transactions.map((doc) => {
        return {
          ...doc,
          type: doc.typeTransaction,
        };
      });

      return formatted;
    } catch (error) {
      console.log("ERROR FETCH TRANSACTIONS DRAGON", error);
      return [];
    }
  },
  async fetchTransactionsWheelFortune(_, { options }) {
    const { params } = options;

    const {
      rouletteId: game,
      operatorId,
      clientId,
      currency,
      fromDate: startDate,
      toDate: endDate,
      playerId: player,
    } = params;

    const filter = { operatorId, clientId, currency, startDate, endDate, player, game, }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter, type: GamesTypes.WheelFortune })


    try {
      const { data, status } = await axiosInsWheel.get(
        "/reports/transactions",
        {
          params: {
            ...params,
            ...filterUuid,
          },
        }
      );

      if (status > 200) return [];

      return data.transactions.map((tr) => ({
        ...tr,
        type: tr.typeTransaction,
        USERNAME: tr.player.username,
        operator: tr.player.operator.name,
        client: tr.clientPlayer.name,
      }));
    } catch (error) {
      console.log(
        "ERROR FETCH TRANSACTIONS WHEEL FORTUNE => REPORT MODULE",
        error
      );
      return [];
    }
  },
  async fetchTransactionsBlackJack(_, { options }) {
    const { params } = options;

    const {
      rouletteId: game,
      operatorId,
      clientId,
      currency,
      fromDate: startDate,
      toDate: endDate,
      playerId: player,
    } = params;

    const filter = { operatorId, clientId, currency, startDate, endDate, player, game, }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter, type: GamesTypes.BlackJack })

    try {
      const { data, status } = await axiosInsBlackJack.get(
        "/reports/transactions",
        {
          params: {
            ...params,
            ...filterUuid,
          },
        }
      );

      if (status > 200) return [];

      return data.transactions.map((tr) => ({
        ...tr,
        type: tr.typeTransaction,
        client: tr.client.name,
      }));
    } catch (error) {
      console.log(
        "ERROR FETCH TRANSACTIONS WHEEL FORTUNE => REPORT MODULE",
        error
      );
      return [];
    }
  },
  async fetchReportsAllGames(_, { oldTransactions, options, type }) {
    const promises = [];

    if (oldTransactions) {
      promises.push(
        store.dispatch("reports/fetchTransactionsRoulette", {
          path: "external-games/all-transactions-old",
          options,
          type,
        })
      );
    } else {
      promises.push(
        store.dispatch("reports/fetchTransactionsRoulette", {
          path: "external-games/all-transactions",
          options,
          type,
        })
      );
    }

    promises.push(
      store.dispatch("reports/fetchTransactionsRoulette", {
        path: "transactions",
        type: "transactions",
        options,
      })
    );

    promises.push(
      store.dispatch("reports/fetchTransactionsDragon", {
        options,
      })
    );

    promises.push(
      store.dispatch("reports/fetchTransactionsWheelFortune", {
        options,
      })
    );

    promises.push(
      store.dispatch("reports/fetchTransactionsBlackJack", {
        options,
      })
    );

    const values = await Promise.allSettled(promises);
    return formatAllTransactions(values, options);
  },
  async fetchDragonBet({ commit }, { item }) {
    try {
      const { bet, typeTransaction: type } = item;
      const { data, status } = await axiosInsDragonTiger.get(
        `/reports/bet/${bet.uuid}`,
        {
          params: {
            type,
          },
        }
      );

      if (status > 201) return console.log("ERROR BET NOT FOUND");

      const { bet: betDetail, earnings, totalEarnings } = data;
      const BET = { ...betDetail, type: "BET" };

      if (earnings) {
        const earningsInBet = addEarningsToBetDragon(earnings);
        commit("setEarnings", totalEarnings);
        return commit("setBet", [BET, earningsInBet]);
      }

      commit("setEarnings", null);
      commit("setBet", [BET]);
    } catch (error) {
      console.log("ERROR FETCH DRAGON BET", error);
    }
  },
  async fetchBlackJackBet({ commit }, { item }) {
    try {
      const { bet, typeTransaction } = item;
      const { data, status } = await axiosInsBlackJack.get(`bet/${bet.uuid}`);

      if (status > 201) return console.log("ERROR BET NOT FOUND");

      const { bet: betInfo } = data;
      let betFormatted = [];

      if (typeTransaction === "credit") {
        const { betTypes } = betInfo;
        for (let i in betTypes) {
          const { value, result } = betTypes[i];
          betFormatted.push({
            type: i18n.t(`black_jack_bet.${i}`),
            bet: value,
            win: result,
          });
        }

        betFormatted.push({
          type: "TOTAL",
          bet: betFormatted.reduce((acc, i) => (acc += i.bet), 0),
          win: betFormatted.reduce((acc, i) => (acc += i.win), 0),
        });

        commit("setFields", [
          {
            key: "type",
            label: "TIPO",
          },
          {
            key: "bet",
            label: "APUESTA",
          },
          {
            key: "win",
            label: "GANANCIA",
          },
        ]);
      }

      if (typeTransaction === "debit") {
        const { betTypes } = betInfo;
        for (let i in betTypes) {
          const { value } = betTypes[i];
          betFormatted.push({
            type: i18n.t(`black_jack_bet.${i}`),
            bet: value,
          });
        }

        betFormatted.push({
          type: "TOTAL",
          bet: betFormatted.reduce((acc, i) => (acc += i.bet), 0),
        });

        commit("setFields", [
          {
            key: "type",
            label: "TIPO",
          },
          {
            key: "bet",
            label: "APUESTA",
          },
        ]);
      }

      commit("setBet", betFormatted);
    } catch (error) {
      console.log("ERROR FETCH DRAGON BET", error);
    }
  },
  async fetchWheelBet({ commit }, { item }) {
    try {
      const { bet, type, game, round } = item;
      const { data, status } = await axiosInsWheel.get(
        `bet/detail/${bet.uuid}`,
        {
          params: {
            type,
            wheelUuid: game.uuid,
            roundUuid: round.uuid,
          },
        }
      );

      if (status > 201) return console.log("ERROR BET NOT FOUND");

      const { bet: betWithEarnings, round: roundWheel } = data;

      const fields = [...wheelBetDetail];

      if (betWithEarnings.find((b) => b.earnings))
        fields.push(...wheelBetDetailEarnings);
      if (betWithEarnings.find((b) => b.earningsJackpot))
        fields.splice(fields.length - 1, 1, ...wheelBetDetailJackpot);

      const initialValue = {
        totalAmount: 0,
        totalEarnings: 0,
        totalEarningsJackpot: 0,
      };

      const total = betWithEarnings.reduce((acc, val) => {
        acc.totalAmount += val.amount;
        acc.totalEarnings += val.earnings || 0;
        acc.totalEarningsJackpot += val.earningsJackpot || 0;
        return acc;
      }, initialValue);

      commit("setJackpotWheel", roundWheel.jackpot);
      commit("setTotalBet", total);
      commit("setFields", [...fields]);
      commit("setBet", betWithEarnings);
      commit("setRoundResult", roundWheel.result)
    } catch (error) {
      console.log("ERROR FETCH DRAGON BET", error);
    }
  },
  async fechBetDetail({ commit }, { _id, type }) {
    try {
      const { data } = await axiosIns.get("/bets/details", {
        params: { _id, type },
      });
      const earnings = data.earnings;
      const formattedEarnings = getEarnings(earnings);

      const betsFetch = data.bet ? data.bet.bet : [];
      const formattedBets = formattBets(betsFetch);

      const bets = addEarningsToBet(formattedBets, formattedEarnings);

      commit("setRoundResult", data.roundResult);
      commit("setBet", bets);
    } catch (err) {
      console.log("ERROR FETCH BET DETAIL", err);
    }
  },
  async fetchViewsBets({ commit }, { queries, filter }) {
    try {
      const promises = [];

      for (let query of queries) {
        const { path, params } = query;
        promises.push(axiosIns.get(path, { params }));
      }

      const data = await Promise.all(promises);

      const { formattedData, formattedFields } = formatResponse(data, filter);

      commit("setViewsBets", formattedData);
      commit("setFields", formattedFields);
    } catch (error) {
      console.log("ERROR FETCH VIEWS BETS", error);
    }
  },
  async fetchVisits() {
    try {
      const { data } = state.visits;

      const hours = Array(24)
        .fill(0)
        .map((_, i) => i);
      const visits = data
        .map(({ hour, value }) => ({ hour: Number(hour), value }))
        .sort((a, b) => a.hour - b.hour);
      const dataForChart = hours.map((hour) => {
        const doc = visits.find((el) => el.hour === hour);
        if (!doc) return { hour, value: 0 };
        return doc;
      });

      const categories = dataForChart.map(({ hour }) => hour);
      const series = dataForChart.map(({ value }) => value);
      return {
        series: [
          {
            name: "VISITS",
            data: series,
          },
        ],
        options: {
          xaxis: {
            categories: categories,
          },
          chart: {
            id: "CHART-VISITS",
          },
        },
      };
    } catch (error) {
      console.log("ERROR FETCH VISITS", error);
    }
  },
  async makeFields(_, { userRole, gameType }) {
    let globalFields = [];
    let adminFields = [];

    switch (gameType) {
      case GamesTypes.Roulette:
        globalFields = rouletteRoundsFields.globalFields;
        adminFields = rouletteRoundsFields.adminFields;
        break;
      case GamesTypes.DragonTiger:
        globalFields = dragonTigerRoundsFields.globalFields;
        adminFields = dragonTigerRoundsFields.adminFields;
        break;
      case GamesTypes.WheelFortune:
        globalFields = wheelRoundsFields.globalFields;
        adminFields = wheelRoundsFields.adminFields;
        break;
      case GamesTypes.BlackJack:
        globalFields = blackJackRoundFields.globalFields;
        // adminFields = wheelRoundsFields.adminFields;
        break;
      case GamesTypes.ALL:
        globalFields = allGamesFields.globalFields;
        break;
      default:
        globalFields = allGamesFields.globalFields;
        break;
    }

    if (userRole === "ADMIN") {
      const fields = [...globalFields, ...adminFields];
      return fields;
    }

    return globalFields;
  },
  async fetchRounds({ commit }, { gameType, filterData, limit, page }) {
    let rounds = [];

    try {
      switch (gameType) {
        case GamesTypes.Roulette:
          rounds = await store.dispatch("reports/fetchRoundsRoulette", {
            filterData,
            limit,
            page,
          });
          break;
        case GamesTypes.DragonTiger:
          rounds = await store.dispatch("reports/fetchRoundsDragon", {
            filterData,
            limit,
            page,
          });
          break;
        case GamesTypes.WheelFortune:
          rounds = await store.dispatch("reports/fetchRoundsWheels", {
            filterData,
            limit,
            page,
          });
          break;
        case GamesTypes.BlackJack:
          rounds = await store.dispatch("reports/fetchRoundsBlackJack", {
            filterData,
            limit,
            page,
          });
          break;
        case GamesTypes.ALL:
          rounds = await store.dispatch("reports/fetchRoundsAll", {
            filterData,
            limit,
            page,
          });
          break;

        default:
          rounds = await store.dispatch("reports/fetchRoundsAll", {
            filterData,
            limit,
            page,
          });
          break;
      }

      commit("setRounds", rounds);
    } catch (error) {
      console.log("ERROR FETCH ROUNDS", error);
    }
  },
  async fetchRoundsRoulette(_, { filterData, limit, page }) {
    try {
      const { data } = await axiosIns.get("round", {
        params: {
          ...filterData,
          limit,
          page,
        },
      });

      if (!data.ok) return [];

      return data.rounds;
    } catch (error) {
      console.log("ERROR FETCH ROUNDS", error);
    }
  },
  async fetchRoundsDragon(_, { filterData, limit, page }) {
    const { roulette: game, operator: operatorId, client: clientId } = filterData;

    const filter = { game, operatorId, clientId }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter, type: GamesTypes.DragonTiger });

    try {
      const { data, status } = await axiosInsDragonTiger.get("reports/rounds", {
        params: {
          ...filterData,
          ...filterUuid,
          limit,
          page,
        },
      });

      if (status > 200) return [];

      return data.rounds;
    } catch (error) {
      console.log("ERROR FETCH ROUNDS DRAGON", error);
      return [];
    }
  },
  async fetchRoundsWheels(_, { filterData, limit, page }) {
    const {
      roulette: game,
      operator: operatorId,
      client: clientId,
      start_date,
      end_date,
    } = filterData;

    const filter = { game, operatorId, clientId, start_date, end_date }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter, type: GamesTypes.WheelFortune })

    try {
      const { data, status } = await axiosInsWheel.get("reports/rounds", {
        params: {
          ...filterData,
          ...filterUuid,
          limit,
          page,
        },
      });

      if (status > 200) return [];

      return data.rounds.map((round) => ({
        ...round,
        jackpotRound: round.jackpot,
      }));
    } catch (error) {
      console.log("ERROR FETCH ROUNDS DRAGON", error);
      return [];
    }
  },
  async fetchRoundsBlackJack(_, { filterData, limit, page }) {
    const {
      roulette: game,
      operator: operatorId,
      client: clientId,
      start_date,
      end_date,
    } = filterData;

    const filter = { game, operatorId, clientId, start_date, end_date, }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter, type: GamesTypes.BlackJack })

    try {
      const { data, status } = await axiosInsBlackJack.get("reports/rounds", {
        params: {
          ...filterData,
          ...filterUuid,
          limit,
          page,
        },
      });

      if (status > 200) return [];

      return data.rounds.map((round) => ({
        ...round,
        start_date: round.startDate,
        end_date: round.endDate,
        jackpotRound: round.jackpot_values,
        winner: round.result,
      }));
    } catch (error) {
      console.log("ERROR FETCH ROUNDS BLACK JACK", error);
      return [];
    }
  },
  async fetchRoundsAll(_, { filterData, limit, page }) {
    const promises = [];

    promises.push(
      store.dispatch("reports/fetchRoundsRoulette", { filterData, limit, page })
    );
    promises.push(
      store.dispatch("reports/fetchRoundsWheels", { filterData, limit, page })
    );
    promises.push(
      store.dispatch("reports/fetchRoundsDragon", { filterData, limit, page })
    );
    promises.push(
      store.dispatch("reports/fetchRoundsBlackJack", {
        filterData,
        limit,
        page,
      })
    );

    const data = await Promise.allSettled(promises);

    return formatAllRounds(data, limit);
  },
  async openResult(_, { gameType }) {
    let data = {};

    switch (gameType) {
      case GamesTypes.DragonTiger:
        data.refs = "dragonTigerResult";
        break;
      case GamesTypes.BlackJack:
        data.refs = "BlackJackResult";
        break;

      default:
        break;
    }

    return data;
  },
  async fetchGgr({ commit }, { filterData: options }) {
    const { params: filterData } = store.getters["reports/cleanFilter"]({
      params: options,
    });

    const { gameType } = filterData;
    try {
      let ggr = {
        data: [],
      };

      switch (gameType) {
        case GamesTypes.Roulette:
          ggr = await store.dispatch("reports/fetchGgrRoulette", {
            filterData,
          });
          break;
        case GamesTypes.DragonTiger:
          ggr = await store.dispatch("reports/fetchGgrDragon", { filterData });
          break;
        case GamesTypes.BlackJack:
          ggr = await store.dispatch("reports/fetchGgrBlackJack", {
            filterData,
          });
          break;
        case GamesTypes.ExternalGames:
          ggr = await store.dispatch("reports/fetchGgrExternalGames", {
            filterData,
          });
          break;
        case GamesTypes.WheelFortune:
          ggr = await store.dispatch("reports/fetchGgrWheelFortune", {
            filterData,
          });
          break;
        case GamesTypes.ALL:
          ggr = await store.dispatch("reports/fetchGgrAll", {
            filterData,
          });
          break;

        default:
          ggr = await store.dispatch("reports/fetchGgrAll", {
            filterData,
          });
          break;
      }

      const { data, total } = ggr;

      commit("setGgr", { data, total });
    } catch (error) {
      console.log("ERROR FETCH GGR -> REPORTS MODULE", error);
    }
  },
  async fetchGgrRoulette(_, { filterData }) {

    const { apiVersion } = filterData

    try {
      const { data, status } = await axiosIns.get(`reports/${apiVersion}/ggr-by-casino`, {
        params: { ...filterData, operatorId: filterData.operator },
      });

      if (status > 201) return [];

      return { ...data };
    } catch (error) {
      console.log("ERROR GETTING GGR IN -> REPORTS MODULE", error);
    }
  },
  async fetchGgrExternalGames(_, { filterData }) {
    const {
      clientId: client,
      fromDate: startDate,
      toDate: endDate,
      ...restFilter
    } = filterData;

    try {
      const { data, status } = await axiosIns.get(
        "reports/external-games/ggr-by-casino",
        {
          params: { client, startDate, endDate, ...restFilter },
        }
      );

      if (status > 201) return [];

      return { ...data };
    } catch (error) {
      console.log("ERROR GETTING GGR IN -> REPORTS MODULE", error);
    }
  },
  async fetchGgrDragon(_, { filterData }) {
    const {
      operator: operatorId,
      clientId,
      currency,
      fromDate: start_date,
      toDate: end_date,
    } = filterData;

    const filter = { operatorId, clientId, currency, start_date, end_date, }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter, type: GamesTypes.DragonTiger, isCurrencyShort: true })

    try {
      const { data, status } = await axiosInsDragonTiger.get(
        "reports/ggr-by-casino",
        {
          params: { ...filterData, ...filterUuid },
        }
      );

      if (status > 201) return [];

      return { ...data };
    } catch (error) {
      console.log("ERROR GETTING GGR IN -> REPORTS MODULE", error);
    }
  },
  async fetchGgrBlackJack(_, { filterData }) {
    const {
      operator: operatorId,
      fromDate: start_date,
      toDate: end_date,
      clientId,
      currency,
    } = filterData;

    const filter = { operatorId, start_date, end_date, clientId, currency, }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter, type: GamesTypes.BlackJack, isCurrencyShort: true })

    try {
      const { data, status } = await axiosInsBlackJack.get(
        "reports/ggr-by-casino",
        {
          params: { ...filterData, ...filterUuid, },
        }
      );

      if (status > 201) return [];

      return { ...data };
    } catch (error) {
      console.log("ERROR GETTING GGR IN -> REPORTS MODULE", error);
    }
  },
  async fetchGgrWheelFortune(_, { filterData }) {
    const {
      operator: operatorId,
      fromDate: startDate,
      toDate: endDate,
      clientId,
      currency,
      apiVersion
    } = filterData;

    const filter = { operatorId, startDate, endDate, clientId, currency, }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter, type: GamesTypes.WheelFortune, isCurrencyShort: false })

    try {
      const { data, status } = await axiosInsWheel.get(
        `reports/${apiVersion}/ggr-by-casino`,
        {
          params: { ...filterData, ...filterUuid, startDate, endDate },
        }
      );

      if (status > 201) return [];

      return { ...data, total: calculateTotal(data.data) };
    } catch (error) {
      console.log("ERROR GETTING GGR IN -> REPORTS MODULE", error);
    }
  },
  async fetchGgrAll(_, { filterData }) {
    const { limit } = filterData;

    let promises = [];

    promises.push(store.dispatch("reports/fetchGgrRoulette", { filterData }));

    promises.push(
      store.dispatch("reports/fetchGgrWheelFortune", { filterData })
    );

    const data = await Promise.allSettled(promises);
    return formatGgrByCasinoAll(data, limit);
  },
  async fetchWls({ commit }, { operator }) {
    try {
      const { data, status } = await axiosIns.get("reports/get-wl", {
        params: {
          operator,
        },
      });

      if (status > 201) return commit("setWls", []);

      const { wls } = data;

      commit("setWls", wls);
    } catch (error) {
      console.log("ERROR FECTH WLS", error);
    }
  },
  // GGR WL
  async fetchGgrByWl({ commit }, { filterData: options }) {

    const { params: filterData } = store.getters["reports/cleanFilter"]({
      params: options,
    });

    const { gameType } = filterData;
    try {
      let ggr = {
        data: [],
        total: {}
      };

      switch (gameType) {
        case GamesTypes.Roulette:
          ggr = await store.dispatch("reports/fetchGgrRouletteWl", {
            filterData,
          });
          break;
        case GamesTypes.WheelFortune:
          ggr = await store.dispatch("reports/fetchGgrWheelWl", {
            filterData,
          });
          break;
        case GamesTypes.ALL:
          ggr = await store.dispatch("reports/fetchGgrByWlAll", {
            filterData,
          });
          break;

        default:
          ggr = await store.dispatch("reports/fetchGgrByWlAll", {
            filterData,
          });
          break;
      }

      const { data } = ggr;

      commit("setGgr", { data, total: getTotalByWls(data) });
    } catch (error) {
      console.log("ERROR FETCH GGR -> REPORTS MODULE", error);
    }
  },
  async fetchGgrRouletteWl(_, { filterData }) {

    const { apiVersion } = filterData

    try {
      const { data, status } = await axiosIns.get(`reports/${apiVersion}/ggr-by-wls`, {
        params: {
          ...filterData,
          clientId: filterData.client,
          operatorId: filterData.operator,
        },
      });

      if (status > 201) return [];

      return {
        ...data,
        total:
        {
          "total_betsAmount": 0,
          "total_profit": 0,
          "total_rounds": 0,
          "total_wins": 0
        }
      };
    } catch (error) {
      console.log("ERROR GETTING GGR IN -> REPORTS MODULE", error);
    }
  },
  async fetchGgrWheelWl(_, { filterData }) {

    const { operator: operatorId, startDate, endDate, client: clientId, currency, apiVersion } = filterData

    const filter = { operatorId, startDate, endDate, clientId, currency, }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter, type: GamesTypes.WheelFortune, isCurrencyShort: false })

    try {
      const { data, status } = await axiosInsWheel.get(`reports/${apiVersion}/ggr-by-wls`, {
        params: { ...filterData, ...filterUuid, clientId: filterUuid.client, startDate, endDate, },
      });

      if (status > 201) return [];

      return {
        ...data, total:
        {
          "total_betsAmount": 0,
          "total_profit": 0,
          "total_rounds": 0,
          "total_wins": 0
        }
      };
    } catch (error) {
      console.log("ERROR GETTING GGR BY WL IN -> REPORTS MODULE", error);
    }
  },
  async fetchGgrByWlAll(_, { filterData }) {
    const { limit } = filterData;

    let promises = [];

    promises.push(store.dispatch("reports/fetchGgrRouletteWl", { filterData }));

    promises.push(
      store.dispatch("reports/fetchGgrWheelWl", { filterData })
    );

    const data = await Promise.allSettled(promises);

    return formatGgrByWlsAll(data, limit);
  },
  // GGR WL

  // GGR BY GAME
  async fetchGgrByGame({ commit }, { filterData: config }) {
    const { params: filterData } = store.getters["reports/cleanFilter"]({
      params: config,
    });

    store.commit('notification/resetPercentage')
    commit("resetGgr");

    try {
      const { gameType } = filterData;

      let ggr = { data: [], total: {} };

      switch (gameType) {
        case GamesTypes.Roulette:
          ggr = await store.dispatch("reports/fetchGgrByGameRoulette", {
            filterData,
          });
          break;
        case GamesTypes.WheelFortune:
          ggr = await store.dispatch("reports/fetchGgrByGameWheelFortune", {
            filterData,
          });
          break;
        case GamesTypes.DragonTiger:
          ggr = await store.dispatch("reports/fetchGgrDragon", { filterData });
          break;
        case GamesTypes.ExternalGames:
          ggr = await store.dispatch("reports/fetchGgrByGameExternalGames", {
            filterData,
          });
          break;
        case GamesTypes.ALL:
          return await store.dispatch("reports/fetchGgrByGameAll", {
            filterData,
          });
        default:
          return await store.dispatch("reports/fetchGgrByGameAll", {
            filterData,
          });
      }

      const { data, total } = ggr;

      commit("setGgr", { data, total });
    } catch (error) {
      console.log("ERROR FETCH GGR BY GAME", error);
    }
  },
  async fetchGgrSteppedRoulettes({ commit }, { filterData }) {
    const data = [];

    let totalized = [];

    for (let game of store.getters["games/getGames"]) {
      const filter = { ...filterData };
      const { limit, rouletteId } = filter;

      if (totalized >= limit) break;

      const dataGame = [];
      const totalizedGame = []

      const { _id, uuid } = game;

      if (rouletteId && rouletteId !== _id) continue

      if (uuid) break

      Object.assign(filter, { rouletteId: _id });

      if (!filterData.clientId) {
        for (let client of store.getters["clients/getClients"]) {
          const { _id: clientId } = client

          Object.assign(filter, { clientId });

          const { data: dataRoulette, total: totalRoulette } = await store.dispatch(
            "reports/fetchGgrByGameRoulette",
            {
              filterData: filter,
            }
          );

          dataGame.push(...dataRoulette)
          totalizedGame.push({ ...totalRoulette })
        }
      } else {
        const { data: dataRoulette, total: totalRoulette } = await store.dispatch(
          "reports/fetchGgrByGameRoulette",
          {
            filterData: filter,
          }
        );

        dataGame.push(...dataRoulette)
        totalizedGame.push({ ...totalRoulette })
      }

      data.push(...concatData(dataGame));

      totalized.push(concatTotalized(totalizedGame));

      store.commit('notification/plusPercentage')
      commit("setGgr", { data: [...data], total: getTotal([...totalized]) });
    }
  },
  async fetchGgrByGameRoulette(_, { filterData }) {

    const { apiVersion } = filterData

    try {
      const { data: dataRoulette, status } = await axiosIns.get(
        `/reports/${apiVersion}/ggr-by-game`,
        {
          params: {
            ...filterData,
          },
        }
      );

      if (status > 201) return null;

      const { data, total } = dataRoulette;

      return { data, total };
    } catch (error) {
      console.log("ERROR FETCH GGR BY GAME", error);
      return { data: [], total: {} };
    }
  },
  async fetchGgrByGameExternalGames(_, { filterData }) {
    const {
      clientId: client,
      fromDate: startDate,
      toDate: endDate,
      ...restFilter
    } = filterData;

    try {
      const { data: dataRoulette, status } = await axiosIns.get(
        `/reports/external-games/ggr-by-game`,
        {
          params: {
            client,
            startDate,
            endDate,
            ...restFilter,
          },
        }
      );

      if (status > 201) return null;

      const { data, total } = dataRoulette;

      return { data, total };
    } catch (error) {
      console.log("ERROR FETCH GGR BY GAME", error);
    }
  },
  async fetchGgrByGameWheelFortune(_, { filterData }) {
    const {
      currency,
      clientId,
      operator: operatorId,
      rouletteId: game,
      fromDate: startDate,
      toDate: endDate,
      apiVersion
    } = filterData;

    const filter = { operatorId, game, startDate, endDate, currency, clientId, }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter, type: GamesTypes.WheelFortune, isCurrencyShort: false })

    try {

      const { data, status } = await axiosInsWheel.get(`reports/${apiVersion}/ggr-by-game`, {
        params: { ...filterData, ...filterUuid },
      });

      if (status > 201) return [];

      return { ...data, total: calculateTotal(data.data) };
    } catch (error) {
      console.log("ERROR GETTING GGR IN -> REPORTS MODULE", error);
      return { data: [], total: {} };
    }
  },
  async fetchGgrByGameWheelFortuneStepped({ commit }, { filterData }) {
    try {

      for (let game of store.getters["wheel/getWheels"]) {
        const filter = { ...filterData, }

        const { _id: rouletteId } = game

        if (filter.rouletteId && filter.rouletteId !== rouletteId) continue

        Object.assign(filter, { rouletteId })

        const { data: dataWheel, total: totalWheel } = await store.dispatch('reports/fetchGgrByGameWheelFortune', { filterData: filter })

        store.commit('notification/plusPercentage')

        const { data, total } = store.getters['reports/getGgr']

        commit("setGgr", { data: [...data, ...dataWheel], total: getTotal([total, totalWheel]) });
      }
    } catch (error) {
      console.log("ERROR GETTING GGR IN -> REPORTS MODULE", error);
    }
  },
  async fetchGgrByGameAll({ commit }, { filterData }) {

    const { apiVersion } = filterData

    if (apiVersion === 'V1') {
      await store.dispatch("reports/fetchGgrSteppedRoulettes", { filterData });

      await store.dispatch('reports/fetchGgrByGameWheelFortuneStepped', { filterData })
    } else {
      const { data, total } = await store.dispatch("reports/fetchGgrByGameRoulette", { filterData });

      const { data: dataWheel, total: totalWheel } = await store.dispatch('reports/fetchGgrByGameWheelFortune', { filterData })

      commit("setGgr", { data: [...data, ...dataWheel], total: getTotal([total, totalWheel]) });
    }

  },
  // GET-JACKPOT-BY-GAME-TYPE
  async getJackpotByGameType(_, { type, round }) {
    try {
      let jackpot = [];

      switch (type) {
        case GamesTypes.Roulette:
          jackpot = round.jackpot_values;
          break;
        case GamesTypes.WheelFortune:
          jackpot = [round.jackpot];
          break;
        case GamesTypes.BlackJack:
          jackpot = round.jackpotValues;

          break;

        default:
          break;
      }

      return jackpot;
    } catch (error) {
      console.log("ERROR GET JACKPOT BY GAME TYPE", error);
    }
  },
  async fetchRoundStatistics({ commit }, filter) {
    const { roulette: game } = filter;

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter: { game }, type: GamesTypes.WheelFortune })

    try {
      const { data } = await axiosInsWheel.get("round/round-statistics", {
        params: { ...filter, ...filterUuid },
      });

      const { statistics } = data;

      if (!statistics || !statistics.length) return commit("setStatistics", []);

      commit("setStatistics", statistics);
      const numbers = statistics.map(({ number }) => number);
      commit("setOptions", { labels: numbers });
      commit(
        "setSeries",
        statistics.map(({ choosePercent }) => choosePercent)
      );
    } catch (error) {
      console.log("ERROR fetchRoundStatistics", error);
      commit("setStatistics", []);
    }
  },
  // JACKPOTS WINNER -> ROULETTE
  async fetchJackpots(
    { commit },
    { gameType, filterData: options, limit, page }
  ) {
    let jackpots = [];

    const { params: filterData } = store.getters["reports/cleanFilter"]({
      params: options,
    });

    try {
      switch (gameType) {
        case GamesTypes.Roulette:
          jackpots = await store.dispatch("reports/fetchJackpotsRoulette", {
            filterData,
            limit,
            page,
          });
          commit('setFields', jackpotRouletteFields)
          break;
        case GamesTypes.WheelFortune:
          jackpots = await store.dispatch("reports/fetchJackpotsWheels", {
            filterData,
            limit,
            page,
          });
          commit('setFields', jackpotWheelFields)
          break;
        default:
          break;
      }

      commit("setJackpots", jackpots);
    } catch (err) {
      console.log("ERROR FETCH JACKPOTS", err);
    }
  },
  async fetchJackpotsRoulette(_, { filterData, limit, page }) {
    try {
      const { data } = await axiosIns.get("reports/jackpots", {
        params: {
          ...filterData,
          limit,
          page,
        },
      });

      const { ok, jackpots } = data;
      if (!ok) return [];

      return jackpots.map(i => ({ ...i, jackpots: i.round.jackpot_values }));
    } catch (error) {
      console.log("ERROR -> fetchJackpotsRoulette", error);
      return [];
    }
  },
  async fetchJackpotsWheels(_, { filterData, limit, page }) {

    const filterDto = {
      startDate: filterData.fromDate,
      endDate: filterData.toDate,
      ...filterData
    }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter: filterDto, type: GamesTypes.WheelFortune })

    try {
      const { data, status } = await axiosInsWheel.get("reports/jackpots", {
        params: {
          ...filterData,
          ...filterUuid,
          limit,
          page,
        },
      });

      if (!status > 201) return [];

      const { jackpots } = data;

      return jackpots.map(i => (
        {
          ...i,
          jackpots: i.round.jackpot,
          type: i.typeTransaction,
          USERNAME: i.player.username,
          operator: i.player.operator.name,
          // client: i.clientPlayer.name,
          // bet: i.bet.bet
        }
      ));
    } catch (error) {
      console.log("ERROR -> fetchJackpotsRoulette", error);
      return [];
    }
  },
  // GGR BY PLAYERS -> ROULETTE
  async fetchGgrByPlayers({ commit }, options) {
    const { params: filter } = store.getters["reports/cleanFilter"]({
      params: options,
    });

    const { gameType } = filter;

    let ggr = [];

    try {
      switch (gameType) {
        case GamesTypes.Roulette:
          ggr = await store.dispatch(
            "reports/fetchGgrByPlayersRoulette",
            filter
          );
          break;
        case GamesTypes.WheelFortune:
          ggr = await store.dispatch(
            "reports/fetchGgrByPlayersWheelFortune",
            filter
          );
          break;
        case GamesTypes.ALL:
          ggr = await store.dispatch("reports/fetchGgrByPlayersAll", filter)
          break;
        default:
          ggr = await store.dispatch("reports/fetchGgrByPlayersAll", filter)
          break;
      }

      commit("setGgrPlayers", ggr);
    } catch (error) {
      console.log("ERROR FETCH GGR BY PLAYERS", error);
      commit("setGgrByPlayers", ggr);
    }
  },
  async fetchGgrByPlayersRoulette(_, filter) {

    try {
      const { data, status } = await axiosIns.get(`/reports/V2/ggr-by-players`, {
        params: { ...filter },
      });

      if (status > 400) throw new Error('not found')

      return data.data;
    } catch (error) {
      console.log("ERROR FETCH GGR BY PLAYERS", error);
      return [];
    }
  },
  async fetchGgrByPlayersWheelFortune(_, filter) {
    const {
      clientId,
      operatorId,
      currency,
      rouletteId: game,
      fromDate: startDate,
      toDate: endDate,
      apiVersion,
    } = filter;

    const filterData = { clientId, operatorId, currency, game, startDate, endDate, }

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter: filterData, type: GamesTypes.WheelFortune })

    try {
      const { data, status } = await axiosInsWheel.get(
        `/reports/${apiVersion}/ggr-by-players`,
        {
          params: {
            ...filter,
            ...filterUuid,
          },
        }
      );

      if (status > 201) return [];

      return data.players;
    } catch (error) {
      console.log("ERROR FETCH GGR BY PLAYERS", error);
      return [];
    }
  },
  async fetchGgrByPlayersAll(_, filter) {
    let promises = []

    promises.push(store.dispatch("reports/fetchGgrByPlayersRoulette", filter))

    promises.push(store.dispatch("reports/fetchGgrByPlayersWheelFortune", filter))

    const data = await Promise.allSettled(promises)

    return formatGgrByPlayerAll(data, filter)
  },
  async fetchGgrByPlayersTotalized({ commit }, options) {
    const { params: filter } = store.getters["reports/cleanFilter"]({
      params: options,
    });

    const { gameType } = filter;

    let ggrTotalized = {};

    try {
      switch (gameType) {
        case GamesTypes.Roulette:
          ggrTotalized = await store.dispatch('reports/fetchGgrByPlayersTotalizedRoulette', filter)
          break;
        case GamesTypes.WheelFortune:
          ggrTotalized = await store.dispatch('reports/fetchGgrByPlayersTotalizedWheelFortune', filter)
          break;

        default:
          break;
      }

      commit("setGgrPlayersTotalized", ggrTotalized);
    } catch (error) {
      console.log("ERROR FETCH GGR BY PLAYERS TOTALIZED", error);
      commit("setGgrPlayersTotalized", ggrTotalized);
    }
  },
  async fetchGgrByPlayersTotalizedRoulette() {
    const total = {
      totalizedBet: 0,
      totalizedWin: 0,
      totalizedProfit: 0,
      totalizedRounds: 0,
    }

    try {
      const ggr = store.getters['reports/mapGgrPlayers']

      for (const i in ggr) {
        const { rounds, bets, wins } = ggr[i]
        total.totalizedRounds += rounds
        total.totalizedBet += bets
        total.totalizedWin += wins
      }

      total.totalizedProfit = total.totalizedBet - total.totalizedWin

      return total
    } catch (error) {
      console.log("ERROR FETCH GGR BY PLAYERS TOTALIZED ROULETTE", error);
      return {};
    }
  },
  async fetchGgrByPlayersTotalizedWheelFortune() {
    const total = {
      totalizedBet: 0,
      totalizedWin: 0,
      totalizedProfit: 0,
      totalizedRounds: 0,
    }

    try {
      const ggr = store.getters['reports/mapGgrPlayers']

      for (const i in ggr) {
        const { rounds, bets, wins } = ggr[i]
        total.totalizedRounds += rounds
        total.totalizedBet += bets
        total.totalizedWin += wins
      }

      total.totalizedProfit = total.totalizedBet - total.totalizedWin

      return total
    } catch (error) {
      console.log('ERROR FETCH PLAYERS TOTALIZAED WHEEL FORTUNE', error);
      return total
    }
  },
  async groupTransactionsProfit({ commit }, filterData) {

    const filterUuid = await store.dispatch('reports/getFilterUuid', { filter: filterData, type: filterData.gameType })


    const filter = {
      ...filterData,
      ...filterUuid,
      startDate: filterData.fromDate,
      endDate: filterData.toDate,
    }

    let data = 0

    try {

      switch (filterData.gameType) {
        case GamesTypes.WheelFortune:

          {
            const { data: dataWheel, status } = await axiosInsWheel.get('reports/V2/group-transaction', {
              params: {
                ...filter
              }
            })

            if (status >= 400) throw new Error('Error wheel group transaction')

            data = dataWheel.count

          }

          break;
        case GamesTypes.Roulette:

          {
            const { data: dataRoulette, status } = await axiosIns.get('reports/V2/group-transaction', {
              params: {
                ...filter
              }
            })

            if (status >= 400) throw new Error('Error wheel group transaction')

            data = dataRoulette.count

          }

          break;

        default:
          throw new Error('Error wheel group transaction')
      }

      commit('setGroupedCount', data)
      commit('setSuccess', true)
    } catch (error) {
      console.log('ERROR GROUP TRANSACTIONS PROFIT', error);
      commit('setSuccess', false)
      commit('setGroupedCount', 0)
    }
  },
  getFilterUuid(_, { filter, type, isCurrencyShort = false }) {
    let filterUuid = {}

    const {
      game,
      operatorId,
      clientId,
      currency,
      start_date,
      end_date,
      startDate,
      endDate,
      player,
    } = filter

    if (game) {
      switch (type) {
        case GamesTypes.DragonTiger:
          {
            const gameExist = store.getters["dragonTiger/getGames"].find(
              (gm) => gm._id === game
            );
            const { uuid } = gameExist;
            filterUuid["game"] = uuid ? uuid : "X";
          }
          break;
        case GamesTypes.BlackJack:
          {
            const gameExist = store.getters["blackJack/getGames"].find(
              (gm) => gm._id === game
            );
            const { uuid } = gameExist;
            filterUuid["game"] = uuid ? uuid : "X";
          }
          break;
        case GamesTypes.WheelFortune:
          {
            const gameExist = store.getters["wheel/getWheels"].find(
              (gm) => gm._id === game
            );
            const { uuid } = gameExist;
            filterUuid["game"] = uuid ? uuid : "X";
          }
          break;
        default:
          break;
      }
    }

    if (clientId) {
      const clientExist = store.getters["clients/getClients"].find(
        (gm) => gm._id === clientId
      );

      if (clientExist) {
        const { uuid } = clientExist;
        filterUuid["client"] = uuid ? uuid : "X";
      } else {
        filterUuid["client"] = "X";
      }

    }

    if (operatorId) {
      const operatorExist = store.getters["operators/getOperators"].find(
        (gm) => gm._id === operatorId
      );

      if (operatorExist) {
        const { uuid } = operatorExist;
        filterUuid["operator"] = uuid ? uuid : "X";
      } else {
        filterUuid["operator"] = "X";
      }
    }

    if (currency) {
      const currencyExist = store.getters["currencies/getCurrencies"].find(
        (gm) => gm._id === currency
      );

      const { uuid, short } = currencyExist;

      if (currencyExist) {
        filterUuid["currency"] =
          isCurrencyShort
            ? short ? short : "X"
            : uuid ? uuid : "X"
      } else {
        filterUuid["currency"] = "X";
      }
    }

    if (start_date) filterUuid['start_date'] = start_date

    if (end_date) filterUuid['end_date'] = end_date

    if (player) filterUuid['player'] = player

    if (startDate) filterUuid['startDate'] = startDate

    if (endDate) filterUuid['endDate'] = endDate

    return filterUuid
  },
};
